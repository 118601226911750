import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputMaskModule as NgNeatInputMaskModule } from '@ngneat/input-mask';

@NgModule({
    imports: [CommonModule, NgNeatInputMaskModule],
    exports: [NgNeatInputMaskModule],
})
export class InputMaskModule {}
