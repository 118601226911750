import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

@Injectable()
export class DateFnsFormatAdapter extends DateFnsAdapter {
    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    public createDate(year: number, month: number, date: number): Date {
        if (month < 0 || month > 11) {
            throw Error(`Invalid month index "${month}".`);
        }

        if (date < 1) {
            throw Error(
                `Invalid date "${date}". Date has to be greater than 0.`
            );
        }

        const result = parse(
            `${year}-${month + 1}-${date}`,
            'y-M-d',
            new Date()
        );

        if (!isValid(result)) {
            throw Error(
                `Invalid date "${date}" for month with index "${month}".`
            );
        }

        return result;
    }
}
