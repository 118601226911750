export enum CalculationParameterEnum {
    AmedNonMedPractitionerReimbursement = 'amedNonMedPractitionerReimbursement',
    Birthdate = 'birthdate',
    Check24Grade = 'check24Grade',
    CreateNewCalculationParameterId = 'createNewCalculationParameterId',
    CureAndRehab = 'cureAndRehab',
    Dental = 'dental',
    DentalTreatment = 'dentalTreatment',
    DentalDentures = 'dentalDentures',
    DirectMedicalConsultationBenefit = 'directMedicalConsultationBenefit',
    EvaluationContributionStability = 'evaluationContributionStability',
    HospitalizationAccommodation = 'hospitalizationAccommodation',
    InsureDate = 'insureDate',
    PdhospitalPayoutAmountValue = 'pdhospitalPayoutAmountValue',
    PdhospitalPayoutStart = 'pdhospitalPayoutStart',
    BetAmount = 'betAmount',
    Provider = 'provider',
    ProvisionContributionReimbursement = 'provisionContributionReimbursement',
    ProvisionCostsharingLimit = 'provisionCostsharingLimit',
    ReducedResults = 'reducedResults',
    TreatmentAboveMaximumRate = 'treatmentAboveMaximumRate',
    TreatmentAttendingDoctor = 'treatmentAttendingDoctor',
    AnnualIncome = 'annualIncome',
    GkvCurrentInsurer = 'gkvCurrentInsurer',
    EffectivePrice = 'effectivePrice',
    Caching = 'caching',
    Overlay = 'overlay',
    Debug = 'debug',
    C24debug = 'c24debug',
    CalculationparameterId = 'calculationparameterId',
    TrackingId = 'trackingId',
    TrackingId2 = 'trackingId2',
}
