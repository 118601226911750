import { InputmaskOptions, createMask } from '@ngneat/input-mask';

export const ibanInputMask: InputmaskOptions<string | null> = createMask({
    mask: 'AA99 I{4} I{4} [I{4}] [I{4}] [I{4}] [I{4}] [I{4}] [I{4}] [I{4}]',
    definitions: {
        I: {
            validator: '[a-zA-Z]|[0-9]',
            casing: 'upper',
        },
    },
    rightAlign: false,
    placeholder: '',
});
