import { BenefitPresetEnum } from '../enums/benefit-preset.enum';

export type BenefitPresetTranslation = {
    [key in BenefitPresetEnum]: string;
};

export const benefitPresetTranslationConstants: BenefitPresetTranslation = {
    [BenefitPresetEnum.Basic]: 'Basis',
    [BenefitPresetEnum.Comfort]: 'Komfort',
    [BenefitPresetEnum.Premium]: 'Premium',
    [BenefitPresetEnum.Custom]: 'Individuell',
};
