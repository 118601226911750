import { createMask, InputmaskOptions } from '@ngneat/input-mask';

const thinSpace = `\u2009`;
const charsToRemove = /[.€\s]/g;

const digits = 2;

/**
 * This config does what it's supposed to do but is rather hacky, due to the radixPoint
 * being set to a char that is not actually a radixPoint.
 * An issue has been opened at https://github.com/RobinHerbots/Inputmask/issues/2674
 */
export const moneyInputMaskConfig: InputmaskOptions<number | null> = createMask(
    {
        alias: 'numeric',
        placeholder: '',
        radixPoint: ',',
        groupSeparator: '.',
        suffix: `${thinSpace}€`,
        digits,
        positionCaretOnClick: 'radixFocus',
        rightAlign: false,
        max: 99999,
        allowMinus: false,
        inputType: 'number',
        nullable: false,
        // The default inputmask onKeyDown handler breaks the onChange event when deleting a digit after the comma.
        // This fixes that problem, but we will have to see if other problems emerge.
        // https://github.com/RobinHerbots/Inputmask/issues/2630
        formatter: (value: number) => value.toFixed(digits),
        onKeyDown: () => undefined,
        parser: (value: string) => {
            value = value.replace(charsToRemove, '');
            value = value.replace(',', '.');
            const parsed = parseFloat(value);
            return isNaN(parsed) ? null : parsed;
        },
    }
);
