import { Directive, HostListener, input } from '@angular/core';
import { C24NgEventTrackingService, EventData } from '@vv-ham/ng-tracking';

@Directive({
    standalone: true,
    selector: '[pkvTrackFocus]',
})
export class TrackFocusDirective {
    public eventData = input.required<EventData | null>({
        alias: 'pkvTrackFocus',
    });

    constructor(private readonly trackingService: C24NgEventTrackingService) {}

    @HostListener('focus')
    public track(): void {
        const eventData = this.eventData();
        if (!eventData) {
            return;
        }

        this.trackingService.trackEvent(eventData);
    }
}
