import { Environment } from '../app/business-domain/environment/models/environment.model';

export const environment: Environment = {
    production: true,
    consultantEmail: 'pkv@check24.de',
    apiUrls: {
        documentDownload: '/api/v1/public/lead',
        errorHandler: '/api/v1/public/logging/javascript-error',
    },
    assetsRoot: '/frontend/mobile/assets/',
};
