import { createMask, InputmaskOptions } from '@ngneat/input-mask';
import { parseDateRule } from '@pkv-frontend/infrastructure/date';

export const dateInputMaskConfig: InputmaskOptions<Date | null> = createMask({
    alias: 'datetime',
    inputFormat: 'dd.mm.yyyy',
    placeholder: 'TT.MM.JJJJ',
    parser: (value: string): Date | null => {
        return parseDateRule(value);
    },
});
