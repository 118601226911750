import { Injectable } from '@angular/core';
import {
    C24NgUnifiedLoginConfig,
    C24NgUnifiedLoginInstanceConfig,
} from '@vv-ham/ng-unified-login';
import {
    DeviceOutputService,
    PhpFrontendDataService,
} from '@pkv-frontend/data-domain/php-frontend-data';
import { PhpFrontendDataAdapter } from '../adapters/php-frontend-data.adapter';

@Injectable({ providedIn: 'root' })
export class UnifiedLoginConfigProviderService {
    constructor(
        private readonly deviceOutputService: DeviceOutputService,
        private readonly phpFrontendDataAdapter: PhpFrontendDataAdapter,
        private readonly phpFrontendDataService: PhpFrontendDataService
    ) {}

    public retrieveConfig(): C24NgUnifiedLoginConfig {
        const config: C24NgUnifiedLoginConfig =
            this.phpFrontendDataAdapter.adapt(
                this.phpFrontendDataService.data.config.unified_login
            );

        return {
            ...config,
            points: 'none',
            deviceOutput: this.deviceOutputService.deviceOutputEnum(),
            nativeAppConfigurationKey: 'App_nopoints',
            useNativeAppLogin: this.deviceOutputService.isApp,
            setLoginData: 'true',
            configKey: 'combined',
        };
    }

    public getRegisterInstanceConfig(
        pointAmount: number
    ): C24NgUnifiedLoginInstanceConfig {
        return {
            nativeAppConfigurationKey: 'App',
            context: 'as',
            border: 'none',
            points: 'checkbox',
            customParam: JSON.stringify({ pointAmount }),
        };
    }
}
