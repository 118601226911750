import { InputmaskOptions, createMask } from '@ngneat/input-mask';

const thinSpace = `\u2009`;
const hairSpace = `\u200A`;
const charsToRemove = /[.€\s]/g;

/**
 * This config does what it's supposed to do but is rather hacky, due to the radixPoint
 * being set to a char that is not actually a radixPoint.
 * An issue has been opened at https://github.com/RobinHerbots/Inputmask/issues/2674
 */
export const incomeInputMaskConfig: InputmaskOptions<number | null> =
    createMask({
        alias: 'currency',
        radixPoint: '€',
        groupSeparator: '.',
        suffix: `${thinSpace}€`,
        digits: '0',
        positionCaretOnClick: 'radixFocus',
        placeholder: `${hairSpace}`,
        rightAlign: false,
        max: 999999,
        unmaskAsNumber: true,
        parser: (value: string) => {
            const parsed = parseInt(value.replace(charsToRemove, ''), 10);
            return isNaN(parsed) ? null : parsed;
        },
    });
