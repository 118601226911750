import { Injectable } from '@angular/core';
import {
    BenefitPresetEnum,
    CalculationParameterInterface,
    GradeEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';

import { YesNoEnum } from '@pkv-frontend/infrastructure/interfaces';
import { QuickFilterContentTypesEnum } from '../enums/quick-filter-content-types.enum';
import { QuickFilterContent } from '../models/quick-filter-content-config.model';
import { ShowBenefitPresetBasicRule } from '../rules/show-benefit-preset-basic.rule';
@Injectable({ providedIn: 'root' })
export class QuickFilterContentService {
    public readonly content: QuickFilterContent = {
        benefitPreset: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'Leistungen',
                    text: (
                        calculationParameter: CalculationParameterInterface
                    ) => {
                        const basic =
                            '<strong>Basis:</strong> Preisgünstige Tarife, die mindestens dem Leistungsniveau der gesetzlichen\n Krankenversicherung entsprechen.';
                        const comfort =
                            '<strong> Komfort:</strong> Tarife mit mindestens umfangreichen Leistungen und sehr gutem\n Preis-Leistungs-Verhältnis.';
                        const premium =
                            '<strong>Premium:</strong> Top-Tarife mit hervorragendem Leistungsniveau, u.a. mit 100% Erstattung beim direkten Facharzt-Besuch oder Erstattung stationärer Arztbehandlungen über dem Höchstsatz (3,5-fach).';
                        let text = [comfort, premium];
                        if (
                            this.showBenefitPresetBasicRule.apply(
                                calculationParameter
                            )
                        ) {
                            text = [basic, comfort, premium];
                        }
                        return text.join('<br /><br />\n');
                    },
                    formControlName: 'benefitPreset',
                    options: [
                        {
                            label: 'Basis',
                            value: BenefitPresetEnum.Basic,
                            visible: (
                                calculationParameter: CalculationParameterInterface
                            ) =>
                                this.showBenefitPresetBasicRule.apply(
                                    calculationParameter
                                ),
                        },
                        {
                            label: 'Komfort',
                            value: BenefitPresetEnum.Comfort,
                        },
                        {
                            label: 'Premium',
                            value: BenefitPresetEnum.Premium,
                        },
                    ],
                },
            ],
        },
        provisionContributionReimbursement: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'Beitragsrückerstattung',
                    text:
                        'Wenn Sie in einem Jahr keine Rechnung bei der Versicherung einreichen, erhalten Sie einen Teil Ihrer\n' +
                        '            bezahlten <strong>Beiträge zurück.</strong>',
                    formControlName: 'provisionContributionReimbursement',
                    options: [
                        {
                            label: 'Alle Tarife anzeigen',
                            value: false,
                            lowestPriceKey: YesNoEnum.No,
                        },
                        {
                            label: 'Nur Tarife mit Beitragsrückerstattung',
                            labelTiny: 'Nur Tarife mit BRE',
                            value: true,
                            lowestPriceKey: YesNoEnum.Yes,
                        },
                    ],
                },
                {
                    title: 'Preisdarstellung',
                    text:
                        'Wir können die <strong>Beitragsrückerstattung im Preis</strong> für Sie berücksichtigen und den effektiven\n' +
                        '            Monatsbeitrag bei Leistungsfreiheit berechnen und anzeigen.',
                    formControlName: 'effectivePrice',
                    options: [
                        {
                            label: 'Monatsbeitrag ohne Beitragsrückerstattung',
                            labelSmall: 'Beitrag ohne Beitragsrückerstattung',
                            labelTiny: 'Ohne Beitragsrückerstattung',
                            value: false,
                            lowestPriceKey: YesNoEnum.No,
                            useEffectivePrice: false,
                        },
                        {
                            label: 'Monatsbeitrag inkl. Beitragsrückerstattung',
                            labelSmall: 'Beitrag inkl. Beitragsrückerstattung',
                            labelTiny: 'Inkl. Beitragsrückerstattung',
                            value: true,
                            lowestPriceKey: YesNoEnum.Yes,
                            useEffectivePrice: true,
                        },
                    ],
                },
            ],
        },
        provider: {
            type: QuickFilterContentTypesEnum.Provider,
            content: [
                {
                    title: 'Versicherer',
                    formControlName: 'provider',
                },
            ],
        },
        provisionCostsharingLimit: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'Selbstbeteiligung',
                    text:
                        'Bei Tarifen mit <strong>Selbstbeteiligung</strong> zahlen Sie die Arztrechnungen bis zur festgelegten Höhe\n' +
                        '            selbst. Erst Rechnungen, die diese Höhe überschreiten, bezahlt die Versicherung. Dafür zahlen Sie geringere\n' +
                        '            monatliche Beiträge.',
                    formControlName: 'provisionCostsharingLimit',
                    options: [
                        {
                            label: '0 €',
                            value: 0,
                        },
                        {
                            label: 'Bis 500 €',
                            value: 500,
                        },
                        {
                            label: 'Bis 1.000 €',
                            value: 1000,
                        },
                        {
                            label: 'Egal',
                            value: -1,
                        },
                    ],
                },
            ],
        },
        dental: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'Zahnersatz',
                    text:
                        'Wenn ein Zahn ausfällt oder vom Zahnarzt entfernt werden muss, ist ein\n' +
                        '            <strong>Zahnersatz</strong> notwendig. Die meisten Tarife erstatten nur einen Teil der Kosten.<br /><br />\n' +
                        '            Die normale Zahnbehandlung wird von allen Tarifen in unserem Vergleich zu 100% erstattet.',
                    formControlName: 'dental',
                    options: [
                        {
                            label: '85% und besser',
                            value: 'premium',
                        },
                        {
                            label: '75% und besser',
                            value: 'comfort',
                        },
                        {
                            label: 'Egal',
                            value: 'basic',
                        },
                    ],
                },
            ],
        },
        check24Grade: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'CHECK24-Tarifnote',
                    text:
                        '<strong>CHECK24</strong> vergibt zur Bewertung jedes Tarifs eine eigene <strong>Tarifnote</strong>, die\n' +
                        '            Ihnen hilft, die Leistungen zu vergleichen. Unsere Experten bewerten die einzelnen Punkte für jeden Tarif\n' +
                        '            und vergeben eine abschließende Gesamtnote.',
                    formControlName: 'check24Grade',
                    options: [
                        {
                            value: GradeEnum.VeryGood,
                            label: 'Sehr gut',
                            lowestPriceKey: 'veryGood',
                        },
                        {
                            value: GradeEnum.Good,
                            label: 'Gut und besser',
                        },
                        {
                            value: GradeEnum.All,
                            label: 'Egal',
                        },
                    ],
                },
            ],
        },
        betAmount: {
            type: QuickFilterContentTypesEnum.FilterChips,
            content: [
                {
                    title: 'Beitragsentlastung im Alter',
                    text:
                        'Ein <strong>Beitragsentlastungstarif</strong> reduziert Ihren monatlichen Beitrag zur privaten Krankenversicherung im Alter\n' +
                        '            um den ausgewählten Betrag. Dadurch kann eine finanzielle Entlastung bei geringerem Einkommen im Alter\n' +
                        '            erreicht werden.',
                    formControlName: 'betAmount',
                    options: [
                        {
                            label: 'Keine',
                            value: 0,
                        },
                        {
                            label: '100 €',
                            value: 100,
                        },
                        {
                            label: '200 €',
                            value: 200,
                        },
                        {
                            label: '300 €',
                            value: 300,
                        },
                        {
                            label: '400 €',
                            value: 400,
                        },
                        {
                            label: '500 €',
                            value: 500,
                        },
                    ],
                },
            ],
        },
    };

    constructor(
        private readonly showBenefitPresetBasicRule: ShowBenefitPresetBasicRule
    ) {}
}
