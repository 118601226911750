import { Component, OnInit } from '@angular/core';
import {
    GoogleTagManagerDataLayerService,
    PreviousUrlPersistenceService,
} from '@pkv-frontend/business-domain/tracking';
import { DeviceOutputService } from '@pkv-frontend/data-domain/application';
import { C24AppService } from '@pkv-frontend/data-domain/c24-app';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

export const selector = 'app-root';

const thankYouPageAreaId = 'Dankeseite';

@Component({
    selector,
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private readonly previousUrlPersistenceService: PreviousUrlPersistenceService,
        private readonly c24AppService: C24AppService,
        private readonly windowRefService: WindowReferenceService,
        private readonly googleTagManagerDataLayerService: GoogleTagManagerDataLayerService,
        private readonly phpFrontendDataService: PhpFrontendDataService,
        private readonly deviceOutputService: DeviceOutputService
    ) {}

    public ngOnInit(): void {
        this.gtmPushFirstDataLayerEntryOnThankYouPage();
        this.previousUrlPersistenceService.set();
        this.c24AppService.setTrackingProduct();

        this.deviceOutputService.isMobileApp() &&
            this.c24AppService.handleChattyState();
    }

    // push first dataLayer entry for conversion tracking on thankYouPage
    // todo: remove in PKV-5003
    private gtmPushFirstDataLayerEntryOnThankYouPage() {
        const url = this.windowRefService.nativeWindow.location.href;

        if (url.includes(FeatureRouteEnum.PageThankYou)) {
            this.googleTagManagerDataLayerService.pushFirstDataLayerEntry(
                thankYouPageAreaId,
                this.phpFrontendDataService.data.config.trackingBaseConfig.pid
            );
        }
    }
}
