import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import * as deepEquals from 'fast-deep-equal';
import { Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
import { dentalMap } from '@pkv-frontend/business-domain/calculation-parameter';
import {
    BenefitPresetEnum,
    CalculationParameterInterface,
    HospitalizationAccommodationEnum,
    TreatmentAttendingDoctorEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { LowestPrices } from '@pkv-frontend/data-domain/result';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import { ResultDataService } from '../../result/services/result-data.service';
import { MatomoFilterTrackingConfigModel } from '../models/matomo-filter-tracking-config.model';
import { ShowHospitalizationAccommodationServiceLevelSelectionRule } from '../rules/show-hospitalization-accommodation-service-level-selection.rule';
import { ShowPdhospitalPayoutAmountRule } from '../rules/show-pdhospital-payout-amount.rule';
import { ShowPdhospitalPayoutStartRule } from '../rules/show-pdhospital-payout-start.rule';
import { BenefitPresetCustomFilterValuesService } from './benefit-preset-custom-filter-values.service';
import { FilterFormDifferenceService } from './filter-form-difference.service';
import { FilterFormDirtyFilterService } from './filter-form-dirty-filter.service';
import { ResultFilterDataService } from './result-filter.data-service';
import { UpdatePdHospitalValuesService } from './update-pd-hospital-value.service';

const INVALID_PROVIDER_ID = 0;

@Injectable({
    providedIn: 'root',
})
export class UpdateFilterService implements OnDestroy {
    constructor(
        private readonly showHospitalizationAccommodationServiceLevelSelectionRule: ShowHospitalizationAccommodationServiceLevelSelectionRule,
        private readonly showPdhospitalPayoutAmountRule: ShowPdhospitalPayoutAmountRule,
        private readonly showPdhospitalPayoutStartRule: ShowPdhospitalPayoutStartRule,
        private readonly resultFilterDataService: ResultFilterDataService,
        private readonly filterFormDifferenceService: FilterFormDifferenceService,
        private readonly updatePdhospitalValueService: UpdatePdHospitalValuesService,
        private readonly filterFormDirtyFilterService: FilterFormDirtyFilterService,
        private readonly benefitPresetCustomFilterValuesService: BenefitPresetCustomFilterValuesService,
        private readonly resultDataService: ResultDataService,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    public filterFormGroup: FormGroup;
    public lowestPrices?: LowestPrices;

    private currentResultFilter: ResultFilter;
    private queryParams: Params;

    private readonly destroy$: Subject<void> = new Subject<void>();

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public initForm(
        calculationParameter: CalculationParameterInterface,
        resultFilter: ResultFilter,
        filterFormGroup: FormGroup,
        matomoFilterTrackingConfigModel: MatomoFilterTrackingConfigModel,
        consultantFilterChange = false
    ) {
        this.activatedRoute.queryParams
            .pipe(distinctUntilChanged(deepEquals), takeUntil(this.destroy$))
            .subscribe((params: Params) => {
                this.queryParams = params;
            });

        this.currentResultFilter = { ...resultFilter };
        this.filterFormGroup = filterFormGroup;

        this.filterFormGroup.valueChanges
            .pipe(
                filter(
                    () =>
                        this.filterFormGroup.valid && this.filterFormGroup.dirty
                ),
                map((resultFilter: ResultFilter) => {
                    if (
                        this.showHospitalizationAccommodationServiceLevelSelectionRule.apply(
                            calculationParameter
                        )
                    ) {
                        return resultFilter;
                    }

                    const hospitalizationAccommodation =
                        resultFilter.hospitalizationAccommodationSingle
                            ? HospitalizationAccommodationEnum.Single
                            : HospitalizationAccommodationEnum.Double;

                    return {
                        ...resultFilter,
                        hospitalizationAccommodation,
                    };
                }),
                map((resultFilter: ResultFilter) => {
                    const filterChanges =
                        this.filterFormDifferenceService.getFilterFormDifference(
                            this.currentResultFilter,
                            this.filterFormDirtyFilterService.retrieve(
                                this.filterFormGroup
                            )
                        );

                    resultFilter.treatmentAttendingDoctor =
                        resultFilter.treatmentAttendingDoctor
                            ? TreatmentAttendingDoctorEnum.HeadDoctor
                            : TreatmentAttendingDoctorEnum.Empty;

                    if (
                        !filterChanges.benefitPreset ||
                        filterChanges.benefitPreset === BenefitPresetEnum.Custom
                    ) {
                        if (Object.keys(filterChanges).length) {
                            resultFilter.benefitPreset =
                                BenefitPresetEnum.Custom;
                            const benefitPresetControl =
                                this.filterFormGroup.get('benefitPreset');
                            benefitPresetControl?.setValue(
                                resultFilter.benefitPreset,
                                {
                                    emitEvent: false,
                                }
                            );
                            benefitPresetControl?.markAsDirty();
                        }
                    } else {
                        const benefitPresetFilterValues =
                            this.benefitPresetCustomFilterValuesService.buildBenefitPreset(
                                filterChanges.benefitPreset,
                                calculationParameter.profession
                            );

                        resultFilter.provider = [];

                        resultFilter = {
                            ...resultFilter,
                            ...benefitPresetFilterValues,
                        };
                    }

                    if (
                        this.showPdhospitalPayoutAmountRule.apply(
                            calculationParameter
                        )
                    ) {
                        resultFilter =
                            this.updatePdhospitalValueService.updatePdhospitalValues(
                                this.filterFormGroup,
                                resultFilter,
                                filterChanges,
                                this.showPdhospitalPayoutStartRule.apply(
                                    calculationParameter
                                ),
                                matomoFilterTrackingConfigModel
                            );
                    }

                    return resultFilter;
                }),
                withLatestFrom(
                    this.resultFilterDataService.loadResultSilently$
                ),
                takeUntil(this.destroy$)
            )
            .subscribe(
                ([resultFilter, loadSilently]: [ResultFilter, boolean]) => {
                    if (resultFilter.provider) {
                        resultFilter.provider = this.filterInvalidProvider(
                            resultFilter.provider
                        );
                    }

                    resultFilter = this.explodeDentalServices(resultFilter);

                    this.currentResultFilter = resultFilter;
                    this.triggerFilterChanges(
                        consultantFilterChange,
                        loadSilently
                    );
                }
            );
    }

    public confirmFilterChange(): void {
        this.resultDataService.resetResult();
        this.triggerFilterChanges(false, false);
        this.resultDataService.commitUrlChange(this.queryParams);
    }

    private explodeDentalServices(resultFilter: ResultFilter): ResultFilter {
        const params = Object.assign({}, resultFilter);

        params.dentalTreatment = dentalMap[resultFilter.dental].dentalTreatment;
        params.dentalDentures = dentalMap[resultFilter.dental].dentalDentures;

        return params;
    }

    private filterInvalidProvider(providers: number[]): number[] {
        if (providers.length === 0) {
            return providers;
        }

        return providers.filter(
            (provider: number) => provider !== INVALID_PROVIDER_ID
        );
    }

    private triggerFilterChanges(
        consultantFilterChange: boolean,
        loadSilent = true
    ): void {
        this.resultFilterDataService.setLoadResultSilently(loadSilent);
        this.resultFilterDataService.updateResultFilter(
            this.currentResultFilter,
            consultantFilterChange,
            loadSilent
        );
    }

    public updateFilter(resultFilter: ResultFilter): void {
        this.filterFormGroup.markAsDirty();
        this.filterFormGroup.patchValue(resultFilter, { emitEvent: true });
    }
}
