import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    C24_NG_UNIFIED_LOGIN_CONFIG,
    C24NgUnifiedLoginModule,
} from '@vv-ham/ng-unified-login';
import { UnifiedLoginConfigProviderService } from './services/unified-login-config-provider.service';

@NgModule({
    imports: [CommonModule, C24NgUnifiedLoginModule.forRoot()],
    providers: [
        {
            provide: C24_NG_UNIFIED_LOGIN_CONFIG,
            useFactory: (
                configProviderService: UnifiedLoginConfigProviderService
            ) => configProviderService.retrieveConfig(),
            deps: [UnifiedLoginConfigProviderService],
        },
    ],
})
export class UnifiedLoginModule {}
