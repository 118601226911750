import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { UnifiedLoginConfigProviderService } from './unified-login-config-provider.service';

const C24SessionCookie = 'c24session';

@Injectable({ providedIn: 'root' })
export class SessionCookieService {
    constructor(
        private readonly cookieService: CookieService,
        private readonly configProviderService: UnifiedLoginConfigProviderService
    ) {}

    public set(sessionId: string): void {
        const providerConfig = this.configProviderService.retrieveConfig();

        if (providerConfig.setSessionCookieOnHost === undefined) {
            return;
        }

        this.cookieService.set(
            C24SessionCookie,
            sessionId,
            365,
            '/',
            providerConfig.setSessionCookieOnHost,
            true,
            'Strict'
        );
    }
}
