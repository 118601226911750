import { Injectable } from '@angular/core';
import { C24NgUnifiedLoginLoginResult } from '@vv-ham/ng-unified-login';
import { Observable, Subject } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';
import { SessionStorageService } from '@pkv-frontend/data-domain/persistence';
import { UserStateData } from '../models/user-state-data.model';

@Injectable({
    providedIn: 'root',
})
export class UnifiedLoginStateService {
    private KEY_UNIFIED_LOGIN_STATE = 'c24-ng-unified-login_state';
    private readonly stateSubject$: Subject<C24NgUnifiedLoginLoginResult> =
        new Subject();

    public readonly state$: Observable<
        C24NgUnifiedLoginLoginResult | undefined
    > = this.stateSubject$.pipe(
        startWith(
            this.sessionStorageService.get<C24NgUnifiedLoginLoginResult>(
                this.KEY_UNIFIED_LOGIN_STATE
            )
        ),
        shareReplay({ bufferSize: 1, refCount: false })
    );

    constructor(private sessionStorageService: SessionStorageService) {}

    public saveSessionData(state: UserStateData): void {
        this.sessionStorageService.set(this.KEY_UNIFIED_LOGIN_STATE, state);
    }

    public getSessionData(): UserStateData | undefined {
        return this.sessionStorageService.get<UserStateData>(
            this.KEY_UNIFIED_LOGIN_STATE
        );
    }
}
