import { FriendAdvertising } from '@pkv-frontend/data-domain/campaigns';
import { PromotionTypeEnum } from '@pkv-frontend/data-domain/result';
import { ComparisonTableDataColorEnum } from '../enums/comparison-table-data.enum';

import { PriceDetailKeyEnum } from '../enums/price-detail-key.enum';
import { PriceDetailTypeEnum } from '../enums/price-detail-type.enum';

export interface Efeedback {
    stars: number;
    totalCount: number;
}

export type FeatureType = 'group' | 'leaf';

export enum FeatureItemTypeEnum {
    File = 'file',
    Icon = 'icon',
    Text = 'text',
}

export enum FeatureItemSignalEnum {
    Positive = 'green',
    Mediocre = 'yellow',
    Negative = 'red',
}

export interface FeatureItem {
    type: FeatureItemTypeEnum;
    isBestFeature: boolean;
    value?: boolean;
    fileLabel?: string;
    fileUrl?: string;
    comment?: string;
    description?: string;
    signal?: FeatureItemSignalEnum;
    showOnPdf?: boolean;
}

export interface Feature {
    allItemsEqual?: boolean;
    color?: FeatureItemSignalEnum;
    grade: number | null;
    isCollapsible?: boolean;
    item?: FeatureItem;
    items?: Feature[];
    key: string;
    label: string | null;
    maxPoints?: number;
    points?: number;
    subLabel: string | null;
    tooltip: string | null;
    type: FeatureType;
}

export interface FollowUpTariff {
    name: string;
    key: string;
    color: ComparisonTableDataColorEnum;
    label: string;
    points: number;
    maxPoints: number;
    isBestFeature: boolean;
    allItemsEqual: boolean;
}

export interface PriceInfo {
    total: number;
    contribution: number;
    contributionPerYear?: number;
    saving: number;
    withoutBonuses?: number;
    savingPerYear?: number;
    savingWithEffectivePrice?: number;
    savingWithEffectivePricePerYear?: number;
    points: number | undefined;
}

export interface PriceDetailsItem {
    type: PriceDetailTypeEnum;
    isCollapsible: boolean;
    isHighlighted: boolean;
    isImportant: boolean;
    priceComponents: PriceComponent[] | null;
}

export interface PriceComponent {
    contribution: number;
    name: string;
    addition?: number;
}

export interface PriceDetails {
    [PriceDetailKeyEnum.Ambulant]: PriceDetailsItem;
    [PriceDetailKeyEnum.Bet]: PriceDetailsItem;
    [PriceDetailKeyEnum.Dental]: PriceDetailsItem;
    [PriceDetailKeyEnum.Stationary]: PriceDetailsItem;
    [PriceDetailKeyEnum.Hospitalization]: PriceDetailsItem;
    [PriceDetailKeyEnum.Aid]: PriceDetailsItem;
    [PriceDetailKeyEnum.Stationary]: PriceDetailsItem;
    [PriceDetailKeyEnum.Other]: PriceDetailsItem;
    [PriceDetailKeyEnum.StateAddition]: PriceDetailsItem;
    [PriceDetailKeyEnum.TotalSum]: PriceDetailsItem;
    [PriceDetailKeyEnum.EmployeeContribution]?: PriceDetailsItem;
    [PriceDetailKeyEnum.PkvPricePerYear]?: PriceDetailsItem;
    [PriceDetailKeyEnum.ReimbursementGuaranteed]?: PriceDetailsItem;
    [PriceDetailKeyEnum.ReimbursementVariable]?: PriceDetailsItem;
    [PriceDetailKeyEnum.EffectivePrice]?: PriceDetailsItem;
    [PriceDetailKeyEnum.YearlyEffectivePrice]?: PriceDetailsItem;
}

export interface Promotion {
    tooltip: string;
    type: PromotionTypeEnum;
    title: string;
}

export interface Interferer {
    enabled: boolean;
    profession: string;
    banderoleText: string;
    tooltipTitle: string;
    tooltipText: string;
}

export enum PointsColorEnum {
    Positive = 'green',
    Mediocre = 'yellow',
    Negative = 'red',
}

export interface Points {
    color: PointsColorEnum;
    maxPoints: number;
    currentValue: number;
}

export interface EffectivePrice {
    pkvPricePerYear: number;
    reimbursementGuaranteed: number;
    reimbursementVariable: number;
    reimbursementTotal: number;
    effectivePrice: number;
    effectivePriceDifference: number;
    yearlyEffectivePrice: number;
}

export interface Tariff {
    tariffId: number;
    tariffVersionId: number;
    providerLogo: string;
    providerName: string;
    providerId: number;
    tariffName: string;
    tariffMarketingName: string;
    tariffShouldOnlyUseMarketingName: boolean;
    priceInfo: PriceInfo;
    priceInfoSource?: PriceInfo;
    priceDetails: PriceDetails;
    priceDetailsSource: PriceDetails;
    // die promotions passen hier noch nich
    promotion?: Promotion;
    isCompletable: boolean;
    grade: number;
    isGoldGrade: boolean;
    isFavorite: boolean;
    isGkv: boolean;
    totalCountBestFeatures: number;
    points: Points;
    interferer?: Interferer;
    friendAdvertising?: FriendAdvertising;
    effectivePrice: EffectivePrice | null;
}

export interface ComparisonItem {
    features: Feature[];
    followUpTariff: FollowUpTariff | null;
    tariff: Tariff;
}
