import { OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
    APP_INITIALIZER,
    ApplicationRef,
    DoBootstrap,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    C24_NG_APP_PROMOTION_MODULE_CONFIG,
    C24NgAppPromotionModuleConfig,
} from '@vv-ham/ng-mobile-native-app-promotion-banner';
import { C24NgTrackingModule } from '@vv-ham/ng-tracking';
import { de } from 'date-fns/locale';
import { CookieService } from 'ngx-cookie-service';
import {
    applicationFeatureKey,
    applicationReducers,
    ApplicationStoreModule,
    FetchCoreFeatureTogglesEffect,
    InitApplicationDataEffect,
    SetEnvironmentEffect,
    SetFirstInfoCookieEffect,
} from '@pkv-frontend/business-domain/application';
import {
    PreviousUrlPersistenceService,
    UrlService,
} from '@pkv-frontend/business-domain/tracking';
import { UnifiedLoginModule } from '@pkv-frontend/business-domain/unified-login';
import { USER_DATA_PERSISTENCE_KEY_TOKEN } from '@pkv-frontend/business-domain/user';
import { CHATTY_VERSION } from '@pkv-frontend/data-domain/c24-app';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { MatomoAbTestingFactory } from '@pkv-frontend/infrastructure/ab-testing';
import { ErrorHandlerModule } from '@pkv-frontend/infrastructure/error-handler';
import {
    DateFnsFormatAdapter,
    DateFormatConstant,
} from '@pkv-frontend/infrastructure/input-mask';
import {
    ASSET_ROOT_TOKEN,
    DEVICE_ENVIRONMENT_TOKEN,
    DeviceEnvironmentEnum,
} from '@pkv-frontend/infrastructure/interfaces';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import {
    AppComponent,
    selector as appComponentSelector,
} from './app.component';
import { AppPromotionBannerConfigBuilderService } from './business-domain/app-promotion/services/app-promotion-banner-config-builder.service';

import { TrackingSettingsService } from './business-domain/tracking/tracking-settings.service';
import { DevelopmentToolbarModule } from './presentation/features/development-toolbar/development-toolbar.module';

registerLocaleData(localeDe);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

const appReducers: ActionReducerMap<State> = {
    [applicationFeatureKey]: applicationReducers,
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        ApplicationStoreModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        C24NgTrackingModule.forRoot(TrackingSettingsService.get),
        DevelopmentToolbarModule,
        EffectsModule.forRoot([
            InitApplicationDataEffect,
            FetchCoreFeatureTogglesEffect,
            SetFirstInfoCookieEffect,
            SetEnvironmentEffect,
        ]),
        ErrorHandlerModule,
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        UnifiedLoginModule,
        OverlayModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        CookieService,
        UrlService,
        PreviousUrlPersistenceService,
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    phpFrontendDataService: PhpFrontendDataService,
                    matomoAbTestingFactory: MatomoAbTestingFactory
                ) =>
                () => {
                    phpFrontendDataService.initialize();
                    matomoAbTestingFactory.init();
                },
            deps: [PhpFrontendDataService, MatomoAbTestingFactory],
            multi: true,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                hideRequiredMarker: true,
            },
        },
        { provide: MAT_DATE_LOCALE, useValue: de },
        {
            provide: DateAdapter,
            useClass: DateFnsFormatAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: DateFormatConstant.DISPLAY_DATE_FORMAT,
                },
                display: {
                    dateInput: DateFormatConstant.DISPLAY_DATE_FORMAT,
                    monthYearLabel: DateFormatConstant.MONTH_YEAR_LABEL,
                    dateA11yLabel: DateFormatConstant.DISPLAY_DATE_FORMAT,
                    monthYearA11yLabel: DateFormatConstant.MONTH_YEAR_LABEL,
                },
            },
        },
        AppPromotionBannerConfigBuilderService,
        {
            provide: C24_NG_APP_PROMOTION_MODULE_CONFIG,
            useFactory: (
                appPromotionBannerConfigService: AppPromotionBannerConfigBuilderService
            ): C24NgAppPromotionModuleConfig =>
                appPromotionBannerConfigService.get(),
            deps: [AppPromotionBannerConfigBuilderService],
        },
        {
            provide: DEVICE_ENVIRONMENT_TOKEN,
            useValue: DeviceEnvironmentEnum.Mobile,
        },
        {
            provide: ASSET_ROOT_TOKEN,
            useValue: environment.assetsRoot,
        },
        {
            provide: CHATTY_VERSION,
            useValue: '2025-2-42',
        },
        {
            provide: USER_DATA_PERSISTENCE_KEY_TOKEN,
            useValue: 'c24.vv.pkv.autofill',
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule implements DoBootstrap {
    public ngDoBootstrap(applicationRef: ApplicationRef): void {
        if (document.querySelector(appComponentSelector)) {
            applicationRef.bootstrap(AppComponent);
        }
    }
}
