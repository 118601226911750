import {
    CalculationParameterInterface,
    InsuredPersonEnum,
    ProfessionEnum,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { QuickfilterItemConfigModel } from '../models/quickfilter-config.model';

const reimbursement: QuickfilterItemConfigModel = {
    key: 'provisionContributionReimbursement',
    name: 'Beitragsrückerstattung',
    type: 'quickfilterOverlay',
};
const provider: QuickfilterItemConfigModel = {
    key: 'provider',
    name: 'Versicherer',
    type: 'quickfilterOverlay',
};
const dental: QuickfilterItemConfigModel = {
    key: 'dental',
    name: 'Zahnersatz',
    type: 'quickfilterOverlay',
};
const grade: QuickfilterItemConfigModel = {
    key: 'check24Grade',
    name: 'CHECK24-Note',
    type: 'quickfilterOverlay',
};
const cureAndRehab: QuickfilterItemConfigModel = {
    key: 'cureAndRehab',
    name: 'Sehr gute Kurleistungen',
    type: 'quickfilterToggle',
};
const hospitalSingle: QuickfilterItemConfigModel = {
    key: 'hospitalizationAccommodation',
    name: '1-Bett-Zimmer',
    type: 'quickfilterToggle',
};
const costsharing: QuickfilterItemConfigModel = {
    key: 'provisionCostsharingLimit',
    name: 'Selbstbeteiligung',
    type: 'quickfilterOverlay',
};
const bet: QuickfilterItemConfigModel = {
    key: 'betAmount',
    name: 'Beitragsentlastung im Alter',
    type: 'quickfilterOverlay',
};
const contributionStability: QuickfilterItemConfigModel = {
    key: 'evaluationContributionStability',
    name: 'mind. gute Beitragsstabilität',
    type: 'quickfilterToggle',
};

export function getQuickfilterConfig(
    calculationParameter?: CalculationParameterInterface
): QuickfilterItemConfigModel[] {
    if (!calculationParameter) {
        return [];
    }

    if (calculationParameter.insuredPerson === InsuredPersonEnum.Child) {
        if (calculationParameter.parentServantOrServantCandidate) {
            return [provider, dental, grade, cureAndRehab, hospitalSingle];
        }
        return [provider, dental, hospitalSingle, costsharing, grade];
    }

    switch (calculationParameter.profession) {
        case ProfessionEnum.Employee: {
            return [reimbursement, provider, costsharing, dental, grade, bet];
        }
        case ProfessionEnum.Freelancer: {
            return [
                reimbursement,
                provider,
                costsharing,
                dental,
                grade,
                contributionStability,
            ];
        }
        case ProfessionEnum.Servant:
            return [provider, dental, grade, cureAndRehab, hospitalSingle];
        case ProfessionEnum.ServantCandidate: {
            return [
                reimbursement,
                provider,
                dental,
                grade,
                cureAndRehab,
                hospitalSingle,
            ];
        }
        default: {
            return [reimbursement, provider, costsharing, dental, grade];
        }
    }
}
