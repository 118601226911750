import { Injectable } from '@angular/core';
import { C24NgUnifiedLoginConfig } from '@vv-ham/ng-unified-login';
import { PhpFrontendData } from '@pkv-frontend/data-domain/php-frontend-data';
import { Adapter } from '@pkv-frontend/infrastructure/adapter';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

@Injectable({ providedIn: 'root' })
export class PhpFrontendDataAdapter
    implements
        Adapter<
            PhpFrontendData['config']['unified_login'],
            C24NgUnifiedLoginConfig
        >
{
    constructor(
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public adapt(
        input: PhpFrontendData['config']['unified_login']
    ): C24NgUnifiedLoginConfig {
        const unifiedLoginConfig: C24NgUnifiedLoginConfig = {
            productName: input.product_name,
        };

        if (input.cookie_host) {
            unifiedLoginConfig.setSessionCookieOnHost = input.cookie_host;
        }

        if (input.environment_host_suffix) {
            unifiedLoginConfig.environmentHostSuffix =
                input.environment_host_suffix;
        }

        if (input.social_login_close_path) {
            unifiedLoginConfig.socialLoginPopupCloseUrl =
                this.windowReferenceService.nativeWindow.location.origin +
                input.social_login_close_path;
        }

        return unifiedLoginConfig;
    }
}
