import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';

import { C24NgSvgIconsModule } from '@vv-ham/ng-svg-icons';
import { WebComponentsMap } from '@pkv-frontend/infrastructure/interfaces';
import { WebComponentRegisterService } from '@pkv-frontend/infrastructure/web-components';
import { ButtonComponent } from '@pkv-frontend/presentation/ui-components/button';
import { ToggleModule } from '@pkv-frontend/presentation/ui-toggle';

import { ButtonModule } from '../../ui-components/button/button.module';

import { ApplicationStateComponent } from './components/application-state/application-state.component';
import { DevelopmentToolbarComponent } from './components/development-toolbar/development-toolbar.component';
import { DevelopmentToolbarButtonComponent } from './components/development-toolbar-button/development-toolbar-button.component';
import { DevelopmentToolbarOverlayComponent } from './components/development-toolbar-overlay/development-toolbar-overlay.component';
import { FeatureToggleComponent } from './components/feature-toggle/feature-toggle.component';
import {
    DevelopmentToolbarWebComponent,
    selector as DevelopmentToolbarWebComponentSelector,
} from './web-components/development-toolbar/development-toolbar.web-component';

const webComponents: WebComponentsMap = {
    [DevelopmentToolbarWebComponentSelector]: DevelopmentToolbarWebComponent,
};

@NgModule({
    declarations: [
        DevelopmentToolbarComponent,
        DevelopmentToolbarOverlayComponent,
        DevelopmentToolbarWebComponent,
        DevelopmentToolbarButtonComponent,
        FeatureToggleComponent,
        ApplicationStateComponent,
    ],
    imports: [
        ButtonComponent,
        ButtonModule,
        C24NgSvgIconsModule,
        CommonModule,
        ToggleModule,
    ],
})
export class DevelopmentToolbarModule {
    constructor(
        webComponentRegisterService: WebComponentRegisterService,
        injector: Injector
    ) {
        webComponentRegisterService.registerWebComponents(
            webComponents,
            injector
        );
    }
}
