import { Location } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// by default Angular strips the trailing slash of an url.
// to prevent this behavior we override this method
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Location as any).stripTrailingSlash = function _stripTrailingSlash(
    url: string
): string {
    return url;
};

if (environment.production) {
    enableProdMode();
}

/**
 * Workaround for jquery mobile adding custom elements and classes to everything it finds,
 * bootstrapping angular after jquery mobile
 */
document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err: Error) => console.error(err));
});
