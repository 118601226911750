import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';

const routes: Routes = [
    {
        path: FeatureRouteEnum.Input1,
        loadChildren: () =>
            import('./presentation/pages/input1/input1.module').then(
                (m) => m.Input1Module
            ),
    },
    {
        path: FeatureRouteEnum.ThirdViewPage,
        loadChildren: () =>
            import('./presentation/pages/third-view/third-view.module').then(
                (m) => m.ThirdViewModule
            ),
        data: {
            tracking: {
                matomo: [
                    {
                        customTitle: location.hostname + '/ThirdView',
                    },
                ],
            },
        },
    },
    {
        path: FeatureRouteEnum.Termination,
        loadChildren: () =>
            import('./presentation/pages/termination/termination.routes').then(
                (modules) => modules.routes
            ),
    },
    {
        path: FeatureRouteEnum.RegisterPage,
        loadChildren: () =>
            import(
                './presentation/pages/register-new/register-new.routes'
            ).then((m) => m.routes),
    },
    {
        path: FeatureRouteEnum.ResultPage,
        loadChildren: () =>
            import('./presentation/pages/result/result.module').then(
                (modules) => modules.ResultModule
            ),
    },
    {
        path: FeatureRouteEnum.PageThankYou,
        loadChildren: () =>
            import('./presentation/pages/thank-you/page-thank-you.module').then(
                (modules) => modules.PageThankYouModule
            ),
    },
    {
        path: FeatureRouteEnum.AppointmentBookingPage,
        loadChildren: () =>
            import(
                './presentation/pages/appointment-booking/page-appointment-booking.module'
            ).then((modules) => modules.PageAppointmentBookingModule),
    },
    {
        path: FeatureRouteEnum.DirectConsultationAppointmentBookingPage,
        loadChildren: () =>
            import(
                './presentation/pages/direct-consultation-appointment-booking/page-direct-consultation/page-direct-consultation.module'
            ).then((modules) => modules.PageDirectConsultationModule),
    },
    {
        path: FeatureRouteEnum.ComparisonPage,
        loadChildren: () =>
            import(
                './presentation/pages/comparison/page-comparison.module'
            ).then((modules) => modules.PageComparisonModule),
    },
    {
        path: FeatureRouteEnum.ErrorPage,
        loadChildren: () =>
            import('./presentation/pages/error/page-error.routes').then(
                (modules) => modules.routes
            ),
    },
    {
        path: `${FeatureRouteEnum.UploadPage}/:access_key`,
        loadChildren: () =>
            import(
                './presentation/pages/document-upload/page-document-upload.module'
            ).then((modules) => modules.PageDocumentUploadModule),
    },
    {
        path: FeatureRouteEnum.FriendAdvertisingReward,
        loadChildren: () =>
            import(
                './presentation/pages/friend-advertising-reward/friend-advertising-reward.module'
            ).then((modules) => modules.FriendAdvertisingRewardModule),
    },
    {
        path: FeatureRouteEnum.RedirectPage,
        loadChildren: () =>
            import('./presentation/pages/redirect/redirect-page.routes').then(
                (modules) => modules.routes
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
