import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    CalculationParameterEnum,
    CalculationParameterInterface,
    benefitPresetTranslationConstants,
} from '@pkv-frontend/data-domain/calculation-parameter';
import { ResultFilter } from '@pkv-frontend/data-domain/result-filter';

import { getQuickfilterConfig } from '../configs/quickfilter.config';
import { QuickfilterItemConfigModel } from '../models/quickfilter-config.model';
import { FilterChipsService } from './filter-chips.service';
import { ResultFilterDataService } from './result-filter.data-service';

type MapType = Map<keyof ResultFilter, QuickfilterItemConfigModel>;

@Injectable({
    providedIn: 'root',
})
export class QuickfilterService {
    public readonly quickfilters$: Observable<QuickfilterItemConfigModel[]> =
        combineLatest([
            this.resultFilterDataService.calculationParameters$,
            this.resultFilterDataService.committedResultFilters$,
            this.resultFilterDataService.filterChips$,
        ]).pipe(
            filter(
                ([calcParams, resultFilter]: [
                    CalculationParameterInterface | undefined,
                    ResultFilter | undefined,
                    string[],
                ]) => !!calcParams && !!resultFilter
            ),
            map(
                ([calcParams, resultFilter, filterChips]: [
                    CalculationParameterInterface,
                    ResultFilter,
                    string[],
                ]): QuickfilterItemConfigModel[] => {
                    const map: MapType = new Map<
                        keyof ResultFilter,
                        QuickfilterItemConfigModel
                    >();

                    // Benefit preset should always be visible and active
                    map.set(
                        'benefitPreset',
                        this.createBenefitPresetQuickfilterItem(resultFilter)
                    );

                    this.addFilterChipsToMap(map, filterChips, resultFilter);

                    this.addQuickfiltersToMap(map, calcParams);

                    return Array.from(map.values());
                }
            )
        );

    constructor(
        private readonly resultFilterDataService: ResultFilterDataService,
        private readonly filterChipsService: FilterChipsService
    ) {}

    private createBenefitPresetQuickfilterItem(
        resultFilter: ResultFilter
    ): QuickfilterItemConfigModel {
        return {
            key: 'benefitPreset',
            name:
                'Leistungen: ' +
                benefitPresetTranslationConstants[resultFilter.benefitPreset],
            type: 'quickfilterOverlay',
            active: true,
        };
    }

    private addFilterChipsToMap(
        map: MapType,
        filterChips: string[],
        resultFilter: ResultFilter
    ): void {
        filterChips.forEach((chipKey: keyof ResultFilter) => {
            // because effectivePrice is part of the provisionContributionReimbursement quickfilter
            if (chipKey === CalculationParameterEnum.EffectivePrice) {
                chipKey =
                    CalculationParameterEnum.ProvisionContributionReimbursement;
            }
            const filterChip =
                this.filterChipsService.getMatchingFilterChip(chipKey);
            if (!filterChip) {
                return;
            }
            map.set(chipKey, {
                key: chipKey,
                name: filterChip.text(resultFilter[chipKey]),
                type: 'filterChip',
                active: true,
            });
        });
    }

    private addQuickfiltersToMap(
        map: MapType,
        calcParams: CalculationParameterInterface
    ): void {
        getQuickfilterConfig(calcParams).forEach(
            (quickfilter: QuickfilterItemConfigModel) => {
                const configFromFilterChip = map.get(quickfilter.key);

                if (configFromFilterChip) {
                    // If the filter is already included, we just have to set the correct quickfilter type
                    map.set(quickfilter.key, {
                        ...configFromFilterChip,
                        type: quickfilter.type,
                    });
                } else {
                    map.set(quickfilter.key, quickfilter);
                }
            }
        );
    }
}
