import { Directive, HostListener, input } from '@angular/core';
import { C24NgEventTrackingService, EventData } from '@vv-ham/ng-tracking';

@Directive({
    standalone: true,
    selector: '[pkvTrackChange]',
})
export class TrackChangeDirective {
    public eventData = input.required<EventData | null>({
        alias: 'pkvTrackChange',
    });

    constructor(private readonly trackingService: C24NgEventTrackingService) {}

    @HostListener('change')
    public track(): void {
        const eventData = this.eventData();
        if (!eventData) {
            return;
        }

        this.trackingService.trackEvent(eventData);
    }
}
