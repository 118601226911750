import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { UserService } from '@pkv-frontend/business-domain/user';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { UnifiedLoginService } from '../services/unified-login.service';

export function isUserLoggedInGuard(preventClosingUL = false): CanMatchFn {
    return () => {
        const userService = inject(UserService);
        const unifiedLoginService = inject(UnifiedLoginService);
        const windowRef = inject(WindowReferenceService);

        if (userService.isLoggedIn || userService.isConsultantLoggedIn) {
            return true;
        }

        unifiedLoginService.openUnifiedLoginLayer('', preventClosingUL);

        return unifiedLoginService.loginSuccessful$.pipe(
            tap(() => windowRef.nativeWindow.location.reload()),
            map(() => false),
            // If false is emitted angular will throw an error, so we just filter everything out as we reload the page anyway
            filter(() => false)
        );
    };
}
