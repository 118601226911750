import { Injectable } from '@angular/core';

import {
    C24NgAppStarRatingApiConfig,
    C24NgAppPromotionModuleConfig,
} from '@vv-ham/ng-mobile-native-app-promotion-banner';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';

const ADJUST_TRACKER = 'v2bbyw7';
const ADJUST_DEEP_LINK_PREFIX = 'c24-app-vergleiche://';
const ADJUST_FALLBACK =
    'https://m.krankenversicherung.check24.de/benutzereingaben1/';

@Injectable()
export class AppPromotionBannerConfigBuilderService {
    constructor(private phpFrontendData: PhpFrontendDataService) {}

    public get(): C24NgAppPromotionModuleConfig {
        return {
            adjustTracker: ADJUST_TRACKER,
            adjustDeepLinkPrefix: ADJUST_DEEP_LINK_PREFIX,
            adjustFallback: ADJUST_FALLBACK,
            wpsetIdentifier:
                this.phpFrontendData.data.config.partnerTrackingConfig.wpset ??
                '',
            gclIdIdentifier:
                this.phpFrontendData.data.config.partnerTrackingConfig.gclid,
            appStarRatingApi: <C24NgAppStarRatingApiConfig>{
                scheme: '',
                host: '',
            },
        };
    }
}
