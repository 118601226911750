import { Injectable } from '@angular/core';
import {
    C24_NG_UNIFIED_LOGIN_VERSION,
    C24NgUnifiedLoginConfig,
} from '@vv-ham/ng-unified-login';
import { Observable, Subject } from 'rxjs';
import { DeviceOutputService } from '@pkv-frontend/data-domain/application';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';
import { UnifiedLoginLayer } from '../models/unified-login-layer.model';
import { UserStateData } from '../models/user-state-data.model';
import { SessionCookieService } from './session-cookie.service';
import { UnifiedLoginConfigProviderService } from './unified-login-config-provider.service';
import { UnifiedLoginStateService } from './unified-login-state.service';

declare const Check24: {
    uliloginlayer: {
        config: { env: string; version?: string };
        init: (config: UnifiedLoginLayer) => void;
        open: () => void;
        reset: () => void;
        useHistoryPush: boolean;
    };
};

enum UnifiedLoginLayerEnvironmentEnum {
    Prod = 'prod',
    Test = 'test',
}

const uliLoginLayerListenerType = 'uli-login-layer';
const uliAppLoginLayerListenerType = 'unified-login';
const uliLoginLayerConfigType = 'check24';
const c24AppConfigurationKey = 'App_nopoints';

@Injectable({ providedIn: 'root' })
export class UnifiedLoginService {
    constructor(
        private readonly configProviderService: UnifiedLoginConfigProviderService,
        private readonly deviceOutputService: DeviceOutputService,
        private readonly userStatePersistenceService: UnifiedLoginStateService,
        private readonly sessionCookieService: SessionCookieService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    private listenerAdded = false;

    private readonly loginSuccessfulSubject$: Subject<void> =
        new Subject<void>();
    public readonly loginSuccessful$: Observable<void> =
        this.loginSuccessfulSubject$.asObservable();

    public openUnifiedLoginLayer(
        prefilledEmail = '',
        preventFromClosing = false,
        useHistoryPush?: boolean
    ): void {
        const encodedEmail = encodeURIComponent(prefilledEmail);
        this.init(encodedEmail, preventFromClosing, useHistoryPush);

        if (
            this.deviceOutputService.isApp() &&
            this.windowReferenceService.nativeWindow.C24App
        ) {
            // native app encodes the email itself
            this.windowReferenceService.nativeWindow.C24App.triggerUnifiedLogin(
                c24AppConfigurationKey,
                prefilledEmail,
                null
            );
        } else {
            Check24.uliloginlayer.open();
        }
    }

    public getState(): UserStateData | undefined {
        return this.userStatePersistenceService.getSessionData();
    }

    public reset(): void {
        Check24.uliloginlayer.reset();
    }

    private init(
        prefilledEmail: string,
        preventFromClosing = false,
        useHistoryPush?: boolean
    ) {
        const config: C24NgUnifiedLoginConfig =
            this.configProviderService.retrieveConfig();

        Check24.uliloginlayer.config.env = this.determineEnvironment(config);
        Check24.uliloginlayer.config.version = C24_NG_UNIFIED_LOGIN_VERSION;

        Check24.uliloginlayer.init({
            deviceoutput: this.getDeviceOutput(),
            api_product: config.productName,
            login_email: prefilledEmail,
            login_phone: '',
            login_type: uliLoginLayerConfigType,
            social_login_callback_url:
                this.windowReferenceService.nativeWindow.location.href,
            social_login_closing_url: config.socialLoginPopupCloseUrl ?? '',
            social_login_base_url: '',
            points: config.points,
        });

        Check24.uliloginlayer.useHistoryPush =
            useHistoryPush ?? Check24.uliloginlayer.useHistoryPush;

        this.addListener();

        if (preventFromClosing) {
            this.preventClosingLoginLayer();
        }

        if (this.deviceOutputService.isApp()) {
            this.hideMobileHeader();
        }
    }

    private determineEnvironment(
        providerConfig: C24NgUnifiedLoginConfig
    ): string {
        return !providerConfig.environmentHostSuffix
            ? UnifiedLoginLayerEnvironmentEnum.Prod
            : UnifiedLoginLayerEnvironmentEnum.Test;
    }

    private addListener(): void {
        if (this.listenerAdded) {
            return;
        }

        const eventType = this.deviceOutputService.isApp()
            ? uliAppLoginLayerListenerType
            : uliLoginLayerListenerType;

        this.windowReferenceService.nativeWindow.addEventListener(
            eventType,
            (e: CustomEvent) => {
                if (!e.detail) {
                    return;
                }

                if (e.detail.ul.STATE === 'browserback') {
                    history.back();
                    return;
                }

                if (e.detail.ul.docHeight !== undefined) {
                    return;
                }

                if (e.detail.ul.STATE === 'anonymous') {
                    return;
                }

                this.loginSuccessfulSubject$.next();

                this.userStatePersistenceService.saveSessionData(e.detail.ul);
                this.sessionCookieService.set(e.detail.ul.STATE);
            }
        );

        this.listenerAdded = true;
    }

    private preventClosingLoginLayer(): void {
        this.hideElement('.c24-uli-loginlayer-close');

        const blockingLayerElement =
            this.windowReferenceService.nativeWindow.document.querySelector(
                '.c24-blocking-layer'
            ) as HTMLElement | null;
        if (blockingLayerElement) {
            blockingLayerElement.style.pointerEvents = 'none';
        }
    }

    private hideMobileHeader(): void {
        this.hideElement('.c24-uli-loginlayer-header');
        this.hideElement('.c24-uli-loginlayer-close');
    }

    private hideElement(querySelector: string): void {
        const element =
            this.windowReferenceService.nativeWindow.document.querySelector(
                querySelector
            ) as HTMLElement | null;
        if (element) {
            element.style.display = 'none';
        }
    }

    private getDeviceOutput(): string {
        const deviceOutput = this.deviceOutputService.getDeviceOutput();
        switch (deviceOutput) {
            case DeviceOutputEnum.App:
            case DeviceOutputEnum.Mobile:
                return 'mobile';
            case DeviceOutputEnum.TabletApp:
            case DeviceOutputEnum.Tablet:
            case DeviceOutputEnum.Desktop:
            default:
                return 'desktop';
        }
    }
}
